import React, { useCallback, useEffect, useState } from "react";
import { Container, ProgressBar, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import Discussion from "../../../components/discussion/Discussion";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import questionFields from "../../../configs/meta/formFields/questionFields";
import {
  createQuestion,
  getQuestions,
} from "../../../services/api/queries/forum";
import { useParams } from "react-router-dom";
import { eventBus } from "../../../services/eventBus";

const ForumDiscussion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const params = useParams();

  const performQuery = useCallback(() => {
    console.log(params);
    setIsLoading(true);
    const res = getQuestions(params)
      .then((info) => {
        if (!info.success) throw info;
        setQuestions(info.data);
        return info;
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    return res;
  }, [params]);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  useEffect(() => {
    eventBus.subscribe("refresh_questions", () => performQuery());

    return () => {
      eventBus.unsubscribe("refresh_questions");
    };
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="Forum Discussion" />
      <Loader className="h-100" minHeight={false} isLoading={isLoading}>
        <Container fluid className="p-0 my-courses">
          <h1 className="h3 mb-3 top-title">Discussion Forum</h1>
          {!questions.length ? (
            <div className="my-courses">
              <div className="d-flex justify-content-center align-items-center my-5">
                <div className="no-course-card">
                  <div className="mx-auto text-center">
                    <h3 className="title">No Discussions Available!</h3>
                    <div className="d-flex justify-content-center align-items-center">
                      <ModalFormOpener
                        key="create"
                        submitService={createQuestion}
                        fields={questionFields}
                        heading={"Write your Post!"}
                        openerText="Write your Post"
                        size="xl"
                        doNotReset={true}
                        successMessage="Question Created!"
                        openerClassName="btn_primary btn_md mx-auto"
                        appendPayload={params}
                        refreshEvent="refresh_questions"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Container>
        {questions.length ? (
          <div className="h-100">
            <div className="row d-flex h-100">
              <div className="col-md-12 h-100">
                <div
                  className="discussion-section p-lg-5 h-100"
                  style={{ cursor: "default" }}
                >
                  <div className="discussion-container">
                    <div className="write-post p-3 mt-3">
                      <ModalFormOpener
                        key="create"
                        submitService={createQuestion}
                        fields={questionFields}
                        heading={"Write your Post!"}
                        openerText="Write your Post"
                        size="xl"
                        doNotReset={true}
                        successMessage="Question Created!"
                        openerClassName="btn btn_primary_outlined write_post_btn"
                        appendPayload={params}
                        refreshEvent="refresh_questions"
                      />
                    </div>

                    <>
                      {questions.map((question, idx) => (
                        <div>
                          <Discussion
                            question={question}
                            course_id={params.course_id}
                          />
                          {idx !== questions.length - 1 && <hr />}
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Loader>
    </React.Fragment>
  );
};
export default ForumDiscussion;
