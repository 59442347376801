/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import avatar1 from "../../../assets/img/avatars/avatar.jpg";
import {
  createAnswer,
  getQuestionDetails,
  updateAnswer,
} from "../../../services/api/queries/forum";
import { useParams } from "react-router-dom";
import moment from "moment";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import answerFields from "../../../configs/meta/formFields/answerFields";
import questionFields from "../../../configs/meta/formFields/questionFields";
import useAuth from "../../../hooks/useAuth";
import { updateQuestion } from "../../../services/api/queries/forum";
import { eventBus } from "../../../services/eventBus";

const DiscussionAnswers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [questionDetails, setQuestionDetails] = useState({});
  const { user } = useAuth();
  const params = useParams();

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getQuestionDetails(params)
      .then((info) => {
        if (!info.success) throw info;
        setQuestionDetails(info.data);
        return info;
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    return res;
  }, [params]);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  useEffect(() => {
    eventBus.subscribe("refresh_question_description", () => performQuery());

    return () => {
      eventBus.unsubscribe("refresh_question_description");
    };
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="My Courses  Page" />
      <Loader className="h-100" minHeight={false} isLoading={isLoading}>
        <div className=" h-100">
          <div className="row d-flex h-100">
            <div className="col-md-12 h-100">
              <div className="discussion-section p-lg-5 h-100">
                <div className=" discussion-container">
                  <div className="p-3 discussion discussions-answers">
                    <div className="d-flex justify-content-between me-3">
                      <h1 className="discussion__title">
                        {questionDetails?.title}
                      </h1>
                      {user?.username ===
                        questionDetails.asked_by?.username && (
                        <ModalFormOpener
                          key="edit"
                          submitService={updateQuestion}
                          fields={questionFields}
                          heading={"Edit your Post!"}
                          openerText={
                            <>
                              <img
                                src="/images/Icons/pencil-square.svg"
                                alt=""
                                className="me-1"
                              />
                              Edit
                            </>
                          }
                          size="xl"
                          doNotReset={true}
                          defaultValues={{
                            ...questionDetails,
                            image: questionDetails.image
                              ? questionDetails.image
                              : [],
                          }}
                          successMessage="Question Created!"
                          openerClassName=" edit_btn"
                          refreshEvent="refresh_questions"
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-row align-items-center">
                        <img
                          src={questionDetails.asked_by?.image || avatar1}
                          // src={avatar1}
                          alt="img"
                          width={50}
                          height={50}
                          className="rounded-circle"
                        />
                        <div className="d-flex flex-column ms-2">
                          <span className="font-weight-bold">
                            {!questionDetails?.asked_by?.first_name &&
                            !questionDetails?.asked_by?.last_name
                              ? "Anonymous User"
                              : `${questionDetails?.asked_by?.first_name} ${questionDetails?.asked_by?.last_name}`}{" "}
                          </span>
                          <small className="me-2">
                            {moment(questionDetails?.created_at).format(
                              "MMM DD, YYYY hh:mm a"
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <p className="text-justify">
                        {questionDetails?.description}
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-row muted-color">
                          <span>{questionDetails?.total_answer} Answers</span>
                        </div>
                        <ModalFormOpener
                          key="edit"
                          submitService={createAnswer}
                          fields={answerFields}
                          heading={"Write your Answer!"}
                          openerText="Write Comment"
                          size="xl"
                          doNotReset={true}
                          successMessage="Answer Posted!"
                          openerClassName="btn btn_primary_outlined write_comment_btn"
                          appendPayload={params}
                          refreshEvent="refresh_question_description"
                        />
                      </div>
                    </div>
                    <hr
                      style={{
                        height: "1px",
                        border: "none",
                      }}
                    />
                    <div className="answers">
                      {questionDetails?.answers?.map((answer) => (
                        <div key={answer.id}>
                          <div className="d-flex justify-content-between align-items-center me-2">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-row align-items-center me-5">
                                <div
                                  className={`profile-image-wrapper ${
                                    answer.is_admin_answer ? "admin-photo" : ""
                                  }`}
                                >
                                  {answer.is_admin_answer ? (
                                    <img
                                      src={"/images/delta-logo.png"}
                                      // src={avatar1}
                                      alt="img"
                                      width={50}
                                      height={50}
                                      style={{ objectFit: "contain" }}
                                      className={`rounded-circle cover-photo`}
                                    />
                                  ) : (
                                    <img
                                      src={answer.answered_by?.image || avatar1}
                                      // src={avatar1}
                                      alt="img"
                                      width={50}
                                      height={50}
                                      style={{ objectFit: "cover" }}
                                      className={`rounded-circle cover-photo`}
                                    />
                                  )}
                                </div>

                                <div className="d-flex flex-column ms-2">
                                  <span className="font-weight-bold">
                                    {answer?.is_admin_answer
                                      ? "Admin"
                                      : !answer?.answered_by?.first_name &&
                                        !answer?.answered_by?.last_name
                                      ? "Anonymous User"
                                      : `${answer?.answered_by?.first_name} ${answer?.answered_by?.last_name}`}
                                  </span>
                                  <small className="me-2">
                                    {moment(answer?.created_at).format(
                                      "MMM DD, YYYY hh:mm a"
                                    )}
                                  </small>
                                </div>
                              </div>
                              <div>
                                {answer.is_admin_answer && (
                                  <div className="btn-see-answers">
                                    Best Answer
                                  </div>
                                )}
                              </div>
                            </div>

                            {user?.username ===
                              answer.answered_by?.username && (
                              <ModalFormOpener
                                key="edit"
                                submitService={updateAnswer}
                                fields={answerFields}
                                heading={"Edit your Answer!"}
                                openerText={
                                  <>
                                    <img
                                      src="/images/Icons/pencil-square.svg"
                                      alt=""
                                      className="me-1"
                                    />
                                    Edit
                                  </>
                                }
                                size="xl"
                                doNotReset={true}
                                defaultValues={{
                                  ...answer,
                                  image: answer.image ? answer.image : [],
                                }}
                                successMessage="Answer Posted!"
                                openerClassName="edit_btn"
                                appendPayload={params}
                                refreshEvent="refresh_question_description"
                              />
                            )}
                          </div>
                          <div className="p-2">
                            <p className="text-justify">{answer.answer}</p>
                          </div>
                          <hr
                            className="mt-0"
                            style={{
                              height: 0.5,
                              border: "none",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </React.Fragment>
  );
};

export default DiscussionAnswers;
