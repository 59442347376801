import endpoints from "constants/endpoints";
import { adminApi } from "services/api/client";

export const getUserExams = async (params) => {
  return await adminApi({
    endpoint: `${endpoints.USER_EXAM}${params.exam_id}`,
    method: "get",
  });
};

export const getExamResult = async (userExamId) => {
  return await adminApi({
    endpoint: `${endpoints.USER_EXAM_RESULT}${userExamId}/`,
    method: "get",
  });
};
