/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import { useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import FileItem from "./FileItem";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ModalTable from "../actions/ModalTable";
import examListViewMeta from "configs/meta/listViewMeta/examListViewMeta";
import { Modal } from "react-bootstrap";
import { validateVimeoURL } from "../../utils/checkValidVemoUrl";
import Rating from "react-rating";
import ratingFields from "../../configs/meta/formFields/ratingFields";
import ModalFormOpener from "../actions/ModalFormOpener";
import { createReview } from "../../services/api/queries/courses";
import moment from "moment";
import ExamStartCountDownTimer from "./ExamStartCountDownTimer";
import ExamStartCountDownModal from "./ExamStartCountDownModal";

const svgList = {
  gallery: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#1E2124"
        d="M.833 10.5A9.667 9.667 0 0110.5.833h29c5.338 0 9.666 4.328 9.666 9.667v29c0 5.338-4.328 9.666-9.666 9.666h-29c-5.34 0-9.667-4.328-9.667-9.666v-29z"
        opacity="0.4"
      ></path>
      <path
        fill="#1E2124"
        fillOpacity="0.58"
        d="M42.064 24.72l7.102 5.113V39.5c0 5.339-4.328 9.667-9.666 9.667h-29c-5.34 0-9.667-4.328-9.667-9.667v-9.667l7.103 5.114a9.667 9.667 0 0012.115-.66l9.898-8.908a9.667 9.667 0 0112.115-.66z"
      ></path>
      <circle
        cx="16.542"
        cy="16.542"
        r="6.042"
        fill="#1E2124"
        fillOpacity="0.58"
      ></circle>
    </svg>
  ),
  star: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      fill="none"
      viewBox="0 0 13 14"
    >
      <path
        fill="#758091"
        d="M12.45 5.163a.624.624 0 00-.542-.422L8.35 4.458 6.81 1.05a.623.623 0 00-1.137 0l-1.54 3.408-3.558.283a.624.624 0 00-.386 1.07l2.629 2.562-.93 4.026a.624.624 0 00.955.66l3.398-2.265 3.399 2.265a.625.625 0 00.947-.69L9.445 8.375l2.831-2.547a.625.625 0 00.174-.665z"
      ></path>
    </svg>
  ),
};

const tabsLecture = [
  {
    name: () => (
      <>
        <span className="d-none d-md-inline-block">LECTURE </span> OVERVIEW{" "}
      </>
    ),
    id: "lecture_overview",
  },
  {
    name: () => (
      <>
        <span className="d-none d-md-inline-block">CLASS</span>NOTE
      </>
    ),
    id: "class_note",
  },
  {
    name: () => <>EXERCISE</>,
    id: "exercise",
  },
];

const tabsExam = [
  {
    name: () => (
      <>
        <span className="d-none d-md-inline-block">Exam</span> Instructions
      </>
    ),
    id: "exam_instructions",
  },
  {
    name: () => (
      <>
        Solution <span className="d-none d-md-inline-block">Manual</span>
      </>
    ),
    id: "solution_manual",
  },
  {
    name: () => (
      <>
        Question <span className="d-none d-md-inline-block">File</span>
      </>
    ),
    id: "question_file",
  },
];

const TopicItemDescription = ({
  data,
  courseId,
  courseSlug,
  courseName,
  review,
}) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showExamStartDateModal, setShowExamStartDateModal] = useState(false);

  const handleExamPageRedirect = (startDate) => {
    if (!startDate) {
      navigate(`/my-courses/course/${courseSlug}/${courseId}/exam/${data.id}`);
    } else {
      const examDate = new Date(startDate);
      const today = new Date();
      const remainingTime = examDate - today;
      if (remainingTime > 0) {
        setShowExamStartDateModal(true);
      } else {
        navigate(
          `/my-courses/course/${courseSlug}/${courseId}/exam/${data.id}`
        );
      }
    }

    // navigate(
    //   `/my-courses/course/${courseSlug}/${courseId}/exam/${data.id}`
    // );
  };

  const [activeTab, setActiveTab] = useState(
    data.content_type === "exam" ? tabsExam[0].id : tabsLecture[0].id
  );

  useEffect(() => {
    setActiveTab(
      data.content_type === "exam" ? tabsExam[0].id : tabsLecture[0].id
    );
  }, [data]);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  function formatTime(duration) {
    if (!duration) return;
    const hours = Math.round(duration?.split(":")[0]);
    const minutes = Math.round(duration?.split(":")[1]);
    // return `${Math.round(duration?.split(":")[0])} hours ${Math.round(duration?.split(":")[1])} minutes `;
    return `${hours ? `${hours} hour` : ""} ${minutes} minutes`;
  }

  const lecture_description = (
    <>
      {/* {console.log(data)} */}
      {data.l_type === "live_class" ? (
        <div className="exam-title-section my-md-5 mx-md-5 mx-sm-2 py-4">
          <div className="exam-titles-container">
            <div className="title-list">
              <div className="list-container my-2 ">
                <div className="title text-center">
                  <p className="pb-0 mb-0 mt-1">
                    <span className="icon-container me-2">
                      <img src={"/images/Icons/exam/exam-clock.svg"} alt="" />
                    </span>{" "}
                    Date :{" "}
                    {moment(data?.joining_date).format("MMM DD, YYYY hh:mm a")}
                  </p>
                </div>
              </div>
              <div className="buttons-container d-flex mx-auto justify-content-center align-items-center mt-3">
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  target={"_blank"}
                  href={data?.joining_link || "#"}
                  rel="noreferrer"
                  className="btn_sm btn_light me-3 text-center"
                >
                  Join
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {!validateVimeoURL(data?.video_url) ? (
            <div className="image-wrapper w-100 mb-4">
              <img
                className="w-100"
                src="/images/Bitmap.png"
                alt="error-video"
              />
            </div>
          ) : (
            <div className="player-wrapper mb-4 mx-0">
              <ReactPlayer
                url={data.video_url}
                controls={true}
                width="100%"
                height="100%"
                className="react-player"
              />
            </div>
          )}
        </>
      )}

      {/* TEST END */}
      <div className="play-bottom-content">
        <div className="selected-item-description">
          <div className="header-container ">
            <h3 className="title">{data?.name}</h3>
          </div>
          <div className="instructor-details">
            <ul className="ms-0 ps-0 d-flex align-items-center justify-content-start flex-wrap">
              <li>
                <span className="name">{courseName}</span>
              </li>
              {/* {data?.instructor_list?.map((el, idx) => (
                <li key={el}>
                  <span key={idx} className="designation">
                    {el || "Instructor Name"}
                  </span>{" "}
                </li>
              ))} */}
              <li className="">
                <span className="link">
                  <ModalFormOpener
                    key="edit"
                    submitService={!review?.rating ? createReview : null}
                    fields={
                      review?.rating
                        ? ratingFields.map((field) => ({
                            ...field,
                            disabled: true,
                          }))
                        : ratingFields
                    }
                    heading={"Give Review and Rating!"}
                    openerText={
                      review?.rating ? "View Rating" : "+ Give Course Rating"
                    }
                    size="xl"
                    doNotReset={true}
                    appendPayload={{ course: courseId }}
                    successMessage="Review Added!"
                    defaultValues={review?.rating ? review : {}}
                    openerClassName="link-primary cursor-pointer"
                    submitButtonText={review?.rating ? "Ok" : "Submit"}
                    refreshEvent="refresh_single_course"
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab_container">
          {tabsLecture.map((el, index) => (
            <button
              onClick={() => setActiveTab(el.id)}
              key={`course-list-tab-${index}`}
              className={`ps-0 content-tab pb-3 ${
                el.id === activeTab ? "tab-active" : ""
              } `}
            >
              {el?.name?.()}
            </button>
          ))}
        </div>
        <div className="active-tab-content-wrapper mb-4">
          {activeTab === "lecture_overview" && (
            <div className="item-container">
              <div className="">
                {data?.overview ? (
                  <div className="content-wrapper">
                    <ReactMarkdown
                      className="content-text"
                      rehypePlugins={[rehypeRaw]}
                    >
                      {data?.overview}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <div className="no-data mx-auto text-center">
                    <div className="svg-container mb-4">{svgList.gallery}</div>
                    <p className="no-data-text">
                      No Lecture Overview Is Available
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {activeTab === "class_note" && (
            <div className="item-container">
              <div className="">
                {data?.class_notes?.map((note) => (
                  <div className="content-wrapper">
                    <FileItem
                      file={note}
                      setShowModal={setShowModal}
                      setSelectedFile={setSelectedFile}
                    />
                  </div>
                )) || (
                  <div className="no-data mx-auto text-center">
                    <div className="svg-container mb-4">{svgList.gallery}</div>
                    <p className="no-data-text">
                      No Lecture Class Note Is Available
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {activeTab === "exercise" && (
            <div className="item-container">
              <div className="">
                {data?.exercise_files?.map((exercise) => (
                  <div className="content-wrapper">
                    {" "}
                    <FileItem
                      file={exercise}
                      setShowModal={setShowModal}
                      setSelectedFile={setSelectedFile}
                    />
                  </div>
                )) || (
                  <div className="no-data mx-auto text-center">
                    <div className="svg-container mb-4">{svgList.gallery}</div>
                    <p className="no-data-text">
                      No Lecture Exercise Is Available
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const exam_description = (
    <>
      {/* EXAM START COUNTDOWN MODAL */}
      <ExamStartCountDownModal
        showModal={showExamStartDateModal}
        setShowModal={setShowExamStartDateModal}
        startDate={data.start_date || 0}
      />
      <div className="mb-4">
        <div className="exam-title-section my-md-5 mx-md-5 mx-sm-2 py-4">
          <div className="exam-titles-container">
            <div className="title-list">
              <div className="item">
                <div className="title text-center">
                  <p className="pb-0 mb-0 mt-1">
                    <span className="icon-container me-2">
                      <img src={"/images/Icons/exam/note-pencil.svg"} alt="" />
                    </span>{" "}
                    {`Exam Title : ${data?.name}`}
                  </p>
                </div>
              </div>
              <div className="list-container my-2 ">
                <div className="title text-center me-3">
                  <p className="pb-0 mb-0 mt-1">
                    <span className="icon-container me-2">
                      <img src={"/images/Icons/exam/marks-paper.svg"} alt="" />
                    </span>{" "}
                    Total Marks : {data.template__total_marks}
                  </p>
                </div>
                <div className="title text-center">
                  <p className="pb-0 mb-0 mt-1">
                    <span className="icon-container me-2">
                      <img src={"/images/Icons/exam/exam-clock.svg"} alt="" />
                    </span>{" "}
                    Duration : {formatTime(data?.duration)}
                  </p>
                </div>
              </div>
              <div className="list-container mb-2">
                <div className="title text-center me-3">
                  <p className="pb-0 mb-0 mt-1">
                    <span className="icon-container me-2">
                      <img src={"/images/Icons/exam/arrow-board.svg"} alt="" />
                    </span>{" "}
                    Your Attempts: {data?.user_attempt}
                  </p>
                </div>
                <div className="title text-center">
                  <p className="pb-0 mb-0 mt-1">
                    <span className="icon-container me-2">
                      <img src={"/images/Icons/exam/arrow-board.svg"} alt="" />
                    </span>{" "}
                    Attempts Left:{" "}
                    {`${
                      data.attempt_limit
                        ? data?.attempt_limit - data?.user_attempt
                        : "Unlimited"
                    }`}
                  </p>
                </div>
              </div>
              <div className="buttons-container d-flex mx-auto justify-content-center align-items-center mt-3">
                <button
                  disabled={
                    data.attempt_limit &&
                    data?.attempt_limit - data?.user_attempt <= 0
                      ? true
                      : false
                  }
                  style={{
                    cursor: `${
                      data.attempt_limit &&
                      data?.attempt_limit - data?.user_attempt <= 0
                        ? "not-allowed"
                        : "pointer"
                    }`,
                  }}
                  onClick={() => {
                    handleExamPageRedirect(data.start_date || 0);
                  }}
                  className="btn_sm btn_light me-3 "
                >
                  Start Exam
                </button>
                <button
                  onClick={() => {
                    setShow(true);
                    // navigate(
                    //   `/my-courses/course/${courseSlug}/${courseId}/exam-result/${data.id}`
                    // );
                  }}
                  className="btn_sm btn_light disabled"
                >
                  See Result
                </button>
                <ModalTable
                  isVisible={show}
                  setIsVisible={setShow}
                  title="Exam List"
                  size="lg"
                  listViewMeta={examListViewMeta({ courseSlug, id: data.id })}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="play-bottom-content">
        <div className="tab_container">
          {tabsExam.map((el, index) => (
            <button
              onClick={() => setActiveTab(el.id)}
              key={`course-list-tab-${index}`}
              className={` content-tab  pb-3 ${
                el.id === activeTab ? "tab-active" : ""
              } `}
            >
              {el?.name()}
            </button>
          ))}
        </div>
        <div className="active-tab-content-wrapper mb-4">
          {activeTab === "exam_instructions" && (
            <div className="item-container">
              <div className="">
                {data?.instruction ? (
                  <div className="content-wrapper">
                    <ReactMarkdown
                      className="content-text"
                      rehypePlugins={[rehypeRaw]}
                    >
                      {data?.instruction}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <div className="no-data mx-auto text-center">
                    <div className="svg-container mb-4">{svgList.gallery}</div>
                    <p className="no-data-text">
                      This Exam has not Instructions
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {activeTab === "solution_manual" && (
            <div className="item-container">
              <div className="">
                {data?.solution?.map((file) => (
                  <div className="content-wrapper">
                    {" "}
                    <FileItem
                      file={file}
                      setShowModal={setShowModal}
                      setSelectedFile={setSelectedFile}
                    />
                  </div>
                )) || (
                  <div className="no-data mx-auto text-center">
                    <div className="svg-container mb-4">{svgList.gallery}</div>
                    <p className="no-data-text">
                      This exam has no question file
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {activeTab === "question_file" && (
            <div className="item-container">
              <div className="">
                {data?.question_file?.map((file) => (
                  <div className="content-wrapper">
                    {" "}
                    <FileItem
                      file={file}
                      setShowModal={setShowModal}
                      setSelectedFile={setSelectedFile}
                    />
                  </div>
                )) || (
                  <div className="no-data mx-auto text-center">
                    <div className="svg-container mb-4">{svgList.gallery}</div>
                    <p className="no-data-text">
                      This exam has no question file
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
  const classnote_description = (
    <>
      <div className="play-bottom-content">
        <div className="note-content-wrapper text-center">
          <div className="text-container ">
            <p>
              <img src="/images/Icons/progress-bar/open-book-dark.svg" alt="" />
              <span className="ms-2">Class Note : {data?.name}</span>
            </p>
          </div>
          <button
            className="btn_md btn_primary_outlined mt-3 read_now_btn"
            onClick={() => {
              setShowModal(true);
              setSelectedFile(data.document);
            }}
          >
            Read Now
          </button>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        {/* <iframe
          title="PDF"
          style={{ height: "90vh" }}
          id="iframepdf"
          width="100%"
          src={`${selectedFile}?page=hsn#toolbar=0`}
        /> */}
        {/* <iframe
          title="PDF"
          style={{ height: "90vh" }}
          id="iframepdf"
          width="100%"
          src={`${selectedFile}?page=hsn#toolbar=0`}
        /> */}
        <object
          width="100%"
          style={{ height: "90vh" }}
          data={`https://docs.google.com/gview?embedded=true&url=${selectedFile}`}
        />
        <div
          style={{
            width: "80px",
            height: "80px",
            position: "absolute",
            opacity: 0,
            right: 0,
            top: "40px",
          }}
        >
          Helloworld;
        </div>
      </Modal>
      {data.content_type === "note"
        ? classnote_description
        : data.content_type === "exam"
        ? exam_description
        : lecture_description}
    </div>
  );
};

export default TopicItemDescription;
// *
