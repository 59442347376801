/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ExamQuestion from "../../../components/exam/ExamQuestion";
import { Form } from "../../../components/formBuilder/FormInput";
import {
  createExam,
  progressCourse,
  questionFinalSubmit,
} from "../../../services/api/queries/courses";
import { SidebarContext } from "../../../contexts/SidebarContext";
import { examSvgList } from "../../../components/exam/examSvgList";
import ExamQuickSearch from "../../../components/exam/ExamQuickSearch";
import { useMemo } from "react";
import Loader from "../../../components/Loader";

const Exam = () => {
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [counter, setCounter] = useState(0);
  const [examData, setExamData] = useState(null);
  const timerRef = useRef();
  const navigate = useNavigate();

  const { setHideFromDisplay } = useContext(SidebarContext);

  const renderExamDuration = (duration = "") => {
    if (!duration) return `unlimited`;
    const durationArr = duration.split(":");
    const hours = Number(durationArr[0]);
    const minutes = Number(durationArr[1]);

    if (hours && minutes) {
      return `${hours} hours ${minutes} minutes `;
    } else if (hours) {
      return `${hours} hours`;
    } else if (minutes) {
      return `${minutes} minutes`;
    } else {
      return `unlimited`;
    }
  };

  useEffect(() => {
    setHideFromDisplay(true);
  }, [setHideFromDisplay]);

  let prevSection = null;

  const params = useParams();

  const sortBasedOnSections = (questions, sections) => {
    let sorted = [];
    // console.log(sections);
    sections.forEach((sec) =>
      questions.forEach((ques) => {
        if (ques.section__name === sec) sorted.push(ques);
      })
    );
    return sorted;
  };

  const fetchExam = useCallback(async () => {
    try {
      const res = await createExam(params);
      if (!res.success) throw res.data;
      const questions = res?.data?.question_data?.questions;
      const sections = res?.data?.section;

      setExamData({
        exam: res.data.exam,
        user_exam: res.data.user_exam,
        questions: sections
          ? sortBasedOnSections(questions, sections)
          : questions,
        total_marks: res.data.question_data.total_marks,
      });
    } catch (error) {
      console.log(error);
    }
  }, [params]);

  // Creating and exam
  useEffect(() => {
    fetchExam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prevent Reload
  useEffect(() => {
    const popWindow = (e) => {
      e.preventDefault();
      window.history.pushState(null, "", window.location.href);
      e.returnValue = "Changes";
      return "Changes";
    };
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", popWindow);
    return () => {
      window.removeEventListener("popstate", popWindow);
    };
  }, []);

  const onAnswerTap = (question_id) => {
    setSelectedQuestions((prev) => [...prev, question_id]);
  };

  const handleSolutionSubmit = async (val) => {
    const data = Object.keys(val).map((key) => ({
      user_exam_question: key,
      answer: val[key],
    }));

    const filterData = data.filter((item, idx) => {
      if (
        item.answer instanceof Array ||
        item.user_exam_question.includes("written")
      ) {
        return item;
      }
    });

    try {
      const res = await questionFinalSubmit(filterData, examData.user_exam.id);
      if (!res.success) throw res.data;
      await progressCourse({
        content_type: "exam",
        content_id: params.exam_id,
        id: params.course_id,
      });
      navigate(`/exam-result/${params.course_slug}/${examData.user_exam.id}/`);
    } catch (err) {
      console.log(err);
      navigate(`/my-courses/${params.course_slug}`);
    }
  };

  const renderQuestions = useMemo(
    () => (
      <Form
        onSubmit={(val) => {
          handleSolutionSubmit(val);
        }}
        defaultValues={{}}
      >
        {(register, errors, undefined, subscribedWatchFields) => {
          return (
            <>
              {/* EXAM FINISH OR SUBMIT MODAL */}
              <div className={`overlay ${showSubmitModal ? "show" : "hidden"}`}>
                <section className="exam_submit_modal ">
                  {!timeUp ? (
                    <div className="close-btn-container">
                      <button
                        type="button"
                        onClick={() => setShowSubmitModal(false)}
                        className="btn-close"
                      ></button>
                    </div>
                  ) : null}

                  <div className="text-center mb-3">
                    {!timeUp ? <h3>Are you sure ?</h3> : <h3>Time is up!</h3>}
                  </div>
                  <button
                    type="submit"
                    style={{ background: "#2f2d51", color: "#fff" }}
                    className="mx-2 mt-2  btn_md modal_submit_btn d-flex justify-content-center align-items-center rounded-pill gap-2"
                  >
                    <span className="paper-plane-icon d-flex justify-content-center align-items-center">
                      {" "}
                      {examSvgList.paperPlaneTwo}
                    </span>{" "}
                    Submit and Review Answers
                  </button>
                </section>
                {/* <div className="overlay hidden" /> */}
              </div>
              <section className="exam-question-section pb-5">
                <div className="question-container">
                  {examData?.questions?.map((question, index) => {
                    const shouldAppendHeader =
                      question.section__name !== prevSection;
                    prevSection = question.section__name;

                    return (
                      <div key={question.id} id={`${index}`} className="pt-3">
                        {shouldAppendHeader && (
                          <div className="mb-4 section-header-container">
                            <h1
                              className="mb-4 header"
                              style={{ color: "#2f2d51" }}
                            >
                              {prevSection}
                            </h1>
                            <hr />
                          </div>
                        )}
                        <div className="question-item">
                          <ExamQuestion
                            errors={errors}
                            question={question}
                            register={register}
                            onChange={onAnswerTap}
                            exam_id={params.exam_id}
                            user_exam_id={examData.user_exam.id}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>
            </>
          );
        }}
      </Form>
    ),
    [examData, showSubmitModal]
  );

  return (
    <>
      {/* MAIN */}
      {examData ? (
        <main className="exam user-select-none">
          <section className="exam-title-section mb-4">
            <div className="exam-titles-container">
              <div className="title-list">
                <div className="item mb-4">
                  <p className="title d-flex align-items-center justify-content-center">
                    {examData.exam?.name || "-"}
                  </p>
                </div>

                <div className="item mb-4">
                  <p className="title d-flex align-items-center justify-content-center">
                    Duration : {renderExamDuration(examData?.exam?.duration)}
                  </p>
                </div>

                <div className="item ">
                  <p className="title d-flex align-items-center justify-content-center">
                    Total Marks : {examData.total_marks || 0}
                  </p>
                </div>
              </div>
            </div>
          </section>
          {renderQuestions}
          <ExamQuickSearch
            examData={examData}
            setShowSubmitModal={setShowSubmitModal}
            selectedQuestions={selectedQuestions}
            setTimeUp={setTimeUp}
          />
        </main>
      ) : (
        <div
          style={{
            height: "100vh",
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Loader className="my-auto" minHeight={false} isLoading={true} />
        </div>
      )}
    </>
  );
};

export default Exam;
