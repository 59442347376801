import inputTypes from "constants/form/inputTypes";

const profileFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "first_name",
    labelText: "First Name",
    placeHolder: "Enter Your First Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "last_name",
    labelText: "Last Name",
    placeHolder: "Enter Your Last Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    disabled: true,
    key: "username",
    labelText: "Phone Number",
    placeHolder: "Enter Your Phone Number",
    rules: {
      required: true,
      disabled: true,
    },
  },
  // {
  //   className: "mb-3",
  //   inputType: inputTypes.TEXT,
  //   key: "email",
  //   labelText: "Your Email",
  //   placeHolder: "Enter Your Email",
  // },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "image",
    labelText: "Profile Picture:",
    multiple: false,
  },
];

export default profileFields;
