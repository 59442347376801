import axios from "axios";
import { refreshAccessToken } from "./queries/auth";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const axiosInstance = axios.create();
export const axiosPublicInstance = axios.create();

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}

axiosInstance.interceptors.response.use(
  (response) => {
    console.log("Entered response");
    return response;
  },
  (error) => {
    console.log("Entered Refresh");
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401) {
      console.log("Entered Refresh 2");
      // window.location = "deltaiutadmission.com";
      if (!isRefreshing && cookies.get("refresh_token")) {
        isRefreshing = true;
        refreshAccessToken({
          refresh_token: cookies.get("refresh_token"),
        })
          .then((newToken) => {
            if (!newToken.success) throw newToken;
            isRefreshing = false;
            const access_token = newToken.data.access_token;
            onRrefreshed(access_token);
          })
          .catch((err) => {
            window.location.replace(process.env.REACT_APP_WEBSITE_BASE_URL);
            throw (
              err || {
                success: false,
                message: "Something went wrong",
              }
            );
          });
      }

      if (!cookies.get("refresh_token")) {
        window.location.replace(process.env.REACT_APP_WEBSITE_BASE_URL);
        throw (
          error.response?.data || {
            success: false,
            message: "Something went wrong",
          }
        );
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      throw (
        error.response?.data || {
          success: false,
          message: "Something went wrong",
        }
      );
    }
  }
);

axiosInstance.defaults.withCredentials = true;
axiosPublicInstance.defaults.withCredentials = true;

export default axiosInstance;
