import React, { useState, useEffect } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const ExamStartCountDownTimer = ({
  startDate,
  setShowModal,
  interval = SECOND,
}) => {
  const [timespan, setTimespan] = useState(new Date(startDate) - Date.now());

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timespan <= 2) setShowModal(false);
      setTimespan((_timespan) => _timespan - interval);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);

  /* If the initial startDate value changes */
  useEffect(() => {
    setTimespan(new Date(startDate) - Date.now());
  }, [startDate]);

  return (
    <div className="d-flex justify-content-center align-items-center mx-auto my-2 gap-2 gap-md-3">
      <TimerCircle value={Math.floor(timespan / DAY)} unit={"DAYS"} />
      <TimerCircle value={Math.floor((timespan / HOUR) % 24)} unit={"HOURS"} />
      <TimerCircle
        value={Math.floor((timespan / MINUTE) % 60)}
        unit={"MINUTES"}
      />
      <TimerCircle
        value={Math.floor((timespan / SECOND) % 60)}
        unit={"SECONDS"}
      />
    </div>
  );
};

export default ExamStartCountDownTimer;

export const TimerCircle = ({ value, unit }) => (
  <div className="d-flex flex-column justify-content-center align-items-center gap-2">
    <div
      className=" rounded-circle d-flex justify-content-center align-items-center"
      style={{
        height: "70px",
        width: "70px",
        border: "1px solid rgb(74, 143, 159)",
      }}
    >
      <h4
        className="m-0 p-0"
        style={{ color: "rgb(74, 143, 159)", textTransform: "uppercase" }}
      >
        {value}
      </h4>
    </div>
    <h4 style={{ color: "rgb(74, 143, 159)" }}>{unit}</h4>
  </div>
);
