import endpoints from "constants/endpoints";
import { publicApi, adminApi } from "services/api/client";
import { axiosPublicInstance } from "../axios";

export const login = async (data) => {
  return await publicApi({
    endpoint: endpoints.USER_LOGIN,
    method: "post",
    data: data,
  });
};

export const logout = async (data) => {
  return await adminApi({
    endpoint: endpoints.LOGOUT,
    method: "delete",
  });
};

export const updateProfile = async (data) => {
  const { u_type, role, course_ids, id, ...newData } = data;
  const payload = {
    ...newData,
    image: Array.isArray(data.image)
      ? data.image[0] || null
      : data.image || null,
  };
  return await adminApi({
    endpoint: `${endpoints.USER_PROFILE}`,
    method: "patch",
    data: payload,
  });
};

export const refreshAccessToken = async (data) => {
  return await publicApi({
    endpoint: `${endpoints.REFRESH_TOKEN}`,
    method: "post",
    data: data,
    withCredentials: true,
  });
};

// export const refreshAccessToken = async (data) => {
//   return await axiosPublicInstance.post(`${endpoints.REFRESH_TOKEN}`, data, {
//     withCredentials: true,
//   });
// };
