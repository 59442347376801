import React, { useState } from "react";
import { examSvgList } from "./examSvgList";
import Timer from "./Timer";

const ExamQuickSearch = ({
  examData,
  selectedQuestions,
  setShowSubmitModal,
  setTimeUp,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <section
      className={`question-search  px-3 py-4 ${collapsed ? "collapsed" : ""}`}
    >
      <div className="top-section">
        <div className="content-container d-flex justify-content-between align-items-center">
          <div className="item">
            <button
              type="button"
              onClick={() => setCollapsed(!collapsed)}
              style={{ background: "none" }}
              className="quick-search-btn d-flex justify-content-center align-items-center gap-2"
            >
              <span className="icon-container d-none d-md-flex justify-content-center align-items-center">
                {examSvgList.dottedCube}
              </span>
              <span className="icon-container d-md-none d-flex justify-content-center align-items-center">
                {examSvgList.quickSearch}
              </span>

              {/* <span className="icon-container d-flex justify-content-center align-items-center">
                {examSvgList.dottedCube}
              </span> */}
              <span className="d-none d-md-inline-block">Quick Search</span>
            </button>
          </div>
          <div className="item">
            <p className="d-flex justify-content-center align-items-center">
              <span className="d-flex justify-content-center align-items-center">
                {examSvgList.timeOutClock}
              </span>
              {/* {formatTime(counter)} */}
              <Timer
                examData={examData}
                setTimeUp={setTimeUp}
                setShowSubmitModal={setShowSubmitModal}
              />
            </p>
          </div>
          <div className="item d-flex align-items-center">
            <div className="submit-btn-container">
              <button
                type="button"
                className="d-flex justify-content-center align-items-center gap-2"
                onClick={() => setShowSubmitModal(true)}
              >
                Submit{" "}
                <span className="d-flex justify-content-center align-items-center">
                  {" "}
                  {examSvgList.paperPlane}
                </span>
              </button>
            </div>
            <button
              className="d-none d-md-inline-block"
              onClick={() => setCollapsed(!collapsed)}
              type="button"
            >
              {examSvgList.upArrow}
            </button>
          </div>
        </div>
      </div>
      <div className="bottom-section mt-4 pb-5">
        <div>
          <div className="options-list d-flex flex-wrap">
            {examData.questions?.map(({ id }, idx) => (
              <a
                className={`question-link ${
                  selectedQuestions?.includes(id) ? "filled" : ""
                }`}
                href={`#${idx}`}
                key={id}
              >
                {idx + 1}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExamQuickSearch;
