import moment from "moment";
import { getUserExams } from "services/api/queries/exam";
import LinkOpener from "components/actions/LinkOpener";

const examListViewMeta = ({ courseSlug, id }) => {
  return {
    columns: [
      {
        Header: "Exam name",
        accessor: "exam.name",
      },
      {
        Header: "Exam Start",
        accessor: "created_at",
        Cell: ({ cell }) =>
          moment(cell.row.original.created_at).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "Exam End",
        accessor: "end_date",
        Cell: ({ value }) => moment(value).format("MMM DD, YYYY hh:mm a"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => {
            return (
              <LinkOpener
                key="exam_result"
                openerText="Exam Result"
                navigateTo={`/exam-result/${courseSlug}/${defaultValues.id}`}
              />
            );
          },
        ],
      },
    ],
    actions: [],
    // queryService: getUserExams,
    queryService: () => getUserExams({ exam_id: id }),
  };
};

export default examListViewMeta;
