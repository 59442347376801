import * as React from "react";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  // console.log(process.env.REACT_APP_WEBSITE_BASE_URL);

  if (isInitialized && !isAuthenticated) {
    // window.location.replace(process.env.REACT_APP_WEBSITE_BASE_URL);

    return <></>;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
