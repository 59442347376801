import React from "react";
import ExamStartCountDownTimer from "./ExamStartCountDownTimer";

const ExamStartCountDownModal = ({ showModal, setShowModal, startDate }) => {
  return (
    <div className={`overlay ${showModal ? "show" : "hidden"}`}>
      <section className="exam_submit_modal ">
        {
          <div className="close-btn-container d-flex justify-content-center align-items-center mt-4">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="btn-close"
            ></button>
          </div>
        }

        <div className="text-center mb-0">
          {<h3 style={{ fontSize: "2rem" }}>Exam starts in:</h3>}
        </div>
        {showModal && (
          <ExamStartCountDownTimer
            startDate={startDate}
            setShowModal={setShowModal}
          />
        )}
      </section>
      {/* <div className="overlay hidden" /> */}
    </div>
  );
};

export default ExamStartCountDownModal;
