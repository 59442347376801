import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resultSvgList } from "./resultSvgList";

const ResultQuickSearch = ({ resultData }) => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const params = useParams();

  return (
    <section
      className={`question-search  px-3 py-4 ${collapsed ? "collapsed" : ""}`}
    >
      <div className="top-section">
        <div className="content-container d-flex justify-content-between align-items-center">
          <div className="item">
            <button
              type="button"
              className="quick-search-btn d-flex justify-content-center align-items-center gap-2"
              style={{ background: "none" }}
              onClick={() => setCollapsed(!collapsed)}
            >
              <span className="icon-container d-flex justify-content-center align-items-center">
                {resultSvgList.dottedCube}
              </span>{" "}
              Quick Search{" "}
            </button>
          </div>
          <div className="item">
            <button
              type="button"
              className="quick-search-btn d-flex justify-content-center align-items-center gap-2"
              style={{ background: "none" }}
              onClick={() => navigate(`/my-courses/${params.course_slug}`)}
            >
              <span className="icon-container d-flex justify-content-center align-items-center">
                {resultSvgList.home}
              </span>{" "}
              Home{" "}
            </button>
          </div>
          <div className="item d-flex align-items-center">
            <button type="button" onClick={() => setCollapsed(!collapsed)}>
              <span className="icon-container d-flex justify-content-center align-items-center">
                {resultSvgList.upArrow}
              </span>{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="bottom-section mt-4 pb-5">
        <div>
          <div className="corrected-options-list d-flex flex-wrap">
            {resultData?.map((item, idx) => (
              <a
                href={`#${idx}`}
                key={idx}
                className={`question-link ${
                  item.is_correct ? "correct" : "incorrect"
                }`}
              >
                {idx + 1}
              </a>
            ))}
            {/* {Array.from({ length: 1000 }).map((_, idx) => (
          <a
            className={`question-link correct `}
            href={`#${idx}`}
            key={idx}
          >
            {idx + 2}
          </a>
        ))} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultQuickSearch;
