import Countdown from "react-countdown";
import React, { useState, useEffect, useCallback, useRef } from "react";

export const FormatTime = ({ duration }) => {
  const sec_num = parseInt(duration, 10);
  const hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  return hours + ":" + minutes + ":" + seconds;
};

const Timer = ({ examData, setTimeUp, setShowSubmitModal }) => {
  const duration = examData.exam?.duration.split(":");
  const durationMilSec =
    parseFloat(duration[0]) * 3600000 +
    parseFloat(duration[1]) * 60000 +
    parseFloat(duration[2]) * 1000;
  const countDownRefExo = useRef();
  const [program, setProgram] = useState({
    step: 0, // not useful for the example
    date: Date.now() + durationMilSec,
  });

  const renderer = ({ formatted }) => {
    return (
      <span>
        {Number(formatted.hours)}: {Number(formatted.minutes)}:{" "}
        {formatted.seconds}
      </span>
    );
  };

  const onComplete = () => {
    setTimeUp(true);
    setShowSubmitModal(true);
  };

  // const [counter, setCounter] = useState(0);
  // const timerRef = useRef();

  // const beginTimer = useCallback((initialTimer) => {
  //   setCounter(initialTimer);
  //   clearInterval(timerRef.current);

  //   timerRef.current = setInterval(() => {
  //     setCounter((val) => {
  //       if (val < 2) {
  //         setTimeUp(true);
  //         setShowSubmitModal(true);
  //         clearInterval(timerRef.current);
  //         return 0;
  //       }
  //       return val - 1;
  //     });
  //   }, 1000);
  // }, []);

  // // Start Timer
  // useEffect(() => {
  //   const duration = examData.exam?.duration;
  //   if (duration) {
  //     const counterValue =
  //       parseInt(duration.split(":")[0]) * 3600 +
  //       parseInt(duration.split(":")[1]) * 60 +
  //       parseInt(duration.split(":")[2]);
  //     beginTimer(counterValue);
  //   }
  // }, [beginTimer, examData]);

  return (
    <>
      {/* <FormatTime duration={counter} /> */}
      <Countdown
        date={program.date}
        ref={countDownRefExo}
        renderer={renderer}
        onComplete={onComplete}
      />
    </>
  );
};

export default Timer;
