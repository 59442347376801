import { Modal } from "react-bootstrap";
import ListView from "../listView/ListView";

const ModalTable = ({
  isVisible,
  setIsVisible,
  title,
  size,
  listViewMeta,
  pagination,
}) => {
  return (
    // <Loader isLoading={isLoading || !!performQuery} minHeight={false}>
    <Modal
      size={size}
      show={isVisible}
      onHide={() => setIsVisible(false)}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body className="m-md-3">
        <ListView meta={listViewMeta} pagination={pagination} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalTable;
