/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { CheckBox, Radio, Textarea } from "../formBuilder/FormInput";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { submitSingleQuestion } from "../../services/api/queries/courses";

const ExamQuestion = ({
  question,
  register,
  onChange,
  errors,
  exam_id,
  user_exam_id,
}) => {
  const singleChoiceSubmit = async (values) => {
    // console.log(values);
    try {
      const res = await submitSingleQuestion(values);
      onChange(values.question_id);
      if (!res.success) throw res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const [isClicked, setIsClicked] = useState(false);

  return (
    <div>
      <div className="question pt-lg-2">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {question.name}
        </ReactMarkdown>
      </div>
      {question?.image ? (
        <div className="image-container mx-auto text-center p-0">
          <img src={question?.image} alt="" />
          {/* <img src="/images/markup-images/index.jpeg" alt="Question image" /> */}
        </div>
      ) : null}
      <div className="options">
        {question.q_type === "written" ? (
          <Textarea
            name={`${question.id}_written`}
            register={register}
            errors={errors}
            rows={4}
            class_name="col-12"
            label={"Type your answer here"}
          />
        ) : question.q_type === "single_choice" ? (
          question?.options?.map((option, index) => (
            <Radio
              class_name="option-item"
              key={`${question.id}-option-${option.key}`}
              index={index}
              disabled={isClicked ? true : false}
              onChange={(e) => {
                singleChoiceSubmit({
                  question_id: question.id,
                  answer: { key: e.target.value },
                  exam_id: exam_id,
                  user_exam_id: user_exam_id,
                });

                setIsClicked(true);
              }}
              // errors={errors}
              register={register}
              name={`${question.id}`}
              value={option.key}
              label={
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {option.name}
                </ReactMarkdown>
              }
              labelClassName="question-option d-flex justify-content-start align-items-start"
              placeholder={option.name}
            />
          ))
        ) : (
          question?.options?.map((option, index) => (
            <CheckBox
              class_name="option-item"
              key={`${question.id}-option-${index + 1}`}
              index={index}
              onChange={(e) => {
                onChange(question.id);
              }}
              // errors={errors}
              register={register}
              name={`${question.id}`}
              value={option.key}
              label={
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {option.name}
                </ReactMarkdown>
              }
              labelClassName="question-option d-flex justify-content-start align-items-start"
              placeholder={option.name}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ExamQuestion;
