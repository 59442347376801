/* eslint-disable prettier/prettier */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { getExamResult } from "services/api/queries/exam";
import ResultQuestion from "components/exam/ResultQuestion";
import { SidebarContext } from "../../../contexts/SidebarContext";
import { validateVimeoURL } from "../../../utils/checkValidVemoUrl";
import ReactPlayer from "react-player";
import { Modal } from "react-bootstrap";
import ResultQuickSearch from "components/exam/ResultQuickSearch";

const ExamResult = () => {
  const { setHideFromDisplay } = useContext(SidebarContext);
  const [modalShow, setModalShow] = useState(false);
  const [explanationVideoUrl, setExplanationVideoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const { user_exam_id } = useParams();

  useEffect(() => {
    setHideFromDisplay(true);
  }, [setHideFromDisplay]);

  let prevSection = null;

  const handleExplanationModalShow = (videoUrl) => {
    if (videoUrl) {
      setExplanationVideoUrl(videoUrl);
      setModalShow(true);
    }
  };

  const sortBasedOnSections = (questions, sections) => {
    let sorted = [];
    sections.forEach((sec) =>
      questions.forEach((ques) => {
        if (ques.section__name === sec) sorted.push(ques);
      })
    );
    return sorted;
  };

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getExamResult(user_exam_id).then((info) => {
      const { exam, user_exam, total_unanswered, questions, section } =
        info.data;
      const sortedQuestions = section
        ? sortBasedOnSections(questions, section)
        : questions;
      setResult({
        exam,
        user_exam,
        total_unanswered,
        questions: sortedQuestions,
      });
      setIsLoading(false);
      return info;
    });
    return res;
  }, [user_exam_id]);

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="Exam Result" />
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="video_container w-100 m-0 p-0">
          <div className="drop_shadow"></div>
          <>
            <div className="w-100">
              {explanationVideoUrl ? (
                <div className="player-wrapper">
                  <ReactPlayer
                    url={explanationVideoUrl}
                    // url={'https://www.youtube.com/embed/_w0Ikk4JY7U'}
                    controls={true}
                    onError={(error) => console.log(error)}
                    width="100%"
                    height="100%"
                    className="react-player"
                  />
                </div>
              ) : (
                <img
                  className="w-100"
                  src="/images/Bitmap.png"
                  alt="img-placeholder"
                />
              )}
            </div>
          </>
        </div>

        <div className="custom_modal_item">
          <button
            onClick={() => setModalShow(false)}
            className="modal_cross_btn d-inline-block"
          >
            <img src="/images/Icons/cross-light.svg" />
          </button>
        </div>
      </Modal>
      {isLoading && <Loader isLoading={isLoading}></Loader>}
      {result?.exam && result?.user_exam && (
        <main className="exam exam-solution user-select-none">
          <section className="exam-title-section mb-4">
            <div className="exam-titles-container">
              <div className="title-list">
                <div className="item mb-3">
                  <p className="title">
                    <span className="svg-container"></span>
                    {result?.exam?.name}
                  </p>
                </div>
                <div className="item mb-3">
                  <p className="title">
                    <span className="svg-container"></span> Total Marks :{" "}
                    {result?.exam?.total_marks}
                  </p>
                </div>
                <ul className="d-flex justify-content-center">
                  <li>
                    <div className="item mb-3">
                      <p className="title">
                        <span className="svg-container"></span> Your Marks :{" "}
                        {result?.user_exam?.total_marks}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="item mb-3">
                      <p className="title">
                        <span className="svg-container"></span> Unanswered :{" "}
                        {result?.total_unanswered}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="item mb-3">
                      <p className="title">
                        <span className="svg-container"></span> Correct Answers:{" "}
                        {result?.user_exam?.total_correct_answer}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="item mb-3">
                      <p className="title">
                        <span className="svg-container"></span> Wrong Answers:{" "}
                        {result?.user_exam?.total_wrong_answer}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="item mb-3">
                      <p className="title">
                        <span className="svg-container"></span> Negative Marks:{" "}
                        {result?.user_exam?.total_negative_marks}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div className="pb-4">
            <section className="exam-question-section pb-5">
              <div className="question-container">
                {result?.questions?.map((question, index) => {
                  const shouldAppendHeader =
                    question.section__name !== prevSection;
                  prevSection = question.section__name;

                  return (
                    <div key={question.id} id={`${index}`} className="pt-3">
                      {shouldAppendHeader && (
                        <div className="mb-4 section-header-container">
                          <h1
                            className="mb-4 header"
                            style={{ color: "#2f2d51" }}
                          >
                            {prevSection}
                          </h1>
                          <hr />
                        </div>
                      )}
                      <div className="question-item">
                        <ResultQuestion
                          question={question}
                          handleExplanationModalShow={
                            handleExplanationModalShow
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
            <ResultQuickSearch resultData={result?.questions} />
          </div>
        </main>
      )}
    </React.Fragment>
  );
};

export default ExamResult;
