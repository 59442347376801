/* eslint-disable quotes */
export const ANSWERS = `/discussions/answers/:course_id/:question_id`;
export const EXAM = `/my-courses/course/:course_slug/:course_id/exam/:exam_id`;
export const EXAM_RESULT = `/exam-result/:course_slug/:user_exam_id`;
export const FORUM_DISCUSSION = `/discussions/forum/:course_id/:forum_id`;
export const INVOICE = `/enrollment/invoice/:invoice_no`;
export const JOIN_DISCUSSION = `/discussions/join`;
export const MY_COURSES = `/`;
export const MY_DISCUSSION = `/discussions/my-discussions`;
export const NOTICES = `/notices`;
export const PAYMENT_HISTORY = `/payments`;
export const PROFILE = `/profile`;
export const SIGN_IN = `/auth/sign-in`;
export const SINGLE_COURSE = `/my-courses/:slug`;
export const VIEW_INVOICE = `/view-invoice/:invoice`;
