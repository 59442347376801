/* eslint-disable prettier/prettier */
import React from "react";
import { renderQuestionMarker } from "../formBuilder/FormInput";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const ResultQuestion = ({ question, handleExplanationModalShow }) => {
  return (
    <div>
      <div className="question d-flex flex-wrap gap-2">
        <div className="">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {question?.name}
          </ReactMarkdown>
        </div>{" "}
        <p className=" my-0 py-0" style={{ color: "red" }}>
          {question.question__no_right_answer
            ? "(No correct answer)"
            : !question.answer
            ? "(No answer selected)"
            : ""}
        </p>
      </div>
      {question?.image ? (
        <div className="image-container mx-auto text-center my-2 py-4 ">
          <img src={question?.image} alt="" />
        </div>
      ) : null}
      <div className="options">
        {question.q_type === "written" ? (
          <div className="col-12">
            <label>Your answer</label>
            <textarea
              name={`${question.id}_written`}
              className="form-control "
              rows="4"
              cols="45"
              value={question.answer}
              // disabled
            ></textarea>
          </div>
        ) : question.q_type === "single_choice" ? (
          question?.options?.map((option, index) => (
            <div
              key={`${question.id}-option-${index + 1}`}
              className={`result-option-item single-choice ${
                !question.question__no_right_answer &&
                (!question.answer || question?.answer?.includes(option.key))
                  ? option.correct
                    ? "correct"
                    : question.answer && "incorrect"
                  : ""
              }`}
            >
              {/* {console.log(question.answer, option)} */}
              <div className="question-option d-flex align-items-center ">
                <span className="option-marker">
                  {renderQuestionMarker(index)}
                </span>
                {/* <span>
                </span> */}
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {option?.name}
                </ReactMarkdown>
                {!question.question__no_right_answer && (
                  <span className="ms-3 icon-container p-0 d-inline-block">
                    {option?.correct && (
                      <img
                        style={{
                          height: "12px",
                          marginTop: "-5px",
                          padding: "0",
                        }}
                        src="/images/Icons/tick-mark.svg"
                        alt="✓"
                      />
                    )}
                    {!option?.correct &&
                      question?.answer?.includes(option.key) && (
                        <img
                          style={{
                            height: "12px",
                            marginTop: "-5px",
                            padding: "0",
                          }}
                          src="/images/Icons/rounded-cross.svg"
                          alt="✓"
                        />
                      )}
                  </span>
                )}
              </div>
            </div>
          ))
        ) : (
          question?.options?.map((option, index) => (
            <div
              key={`${question.id}-option-${index + 1}`}
              className={`result-option-item multiple-choice  ${
                !question.question__no_right_answer &&
                (!question.answer || question.answer?.includes(option.key))
                  ? option.correct
                    ? "correct"
                    : question.answer && "incorrect"
                  : ""
              }`}
            >
              <div className="question-option d-flex align-items-center">
                <span className="option-marker">
                  {renderQuestionMarker(index)}
                </span>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {option?.name}
                </ReactMarkdown>
                {!question.question__no_right_answer && (
                  <span className="ms-3 icon-container p-0 d-inline-block">
                    {option?.correct && (
                      <img
                        style={{
                          height: "12px",
                          marginTop: "-5px",
                          padding: "0",
                        }}
                        src="/images/Icons/tick-mark.svg"
                        alt="✓"
                      />
                    )}
                    {!option?.correct &&
                      question.correct_answer?.includes(option.key) && (
                        <img
                          style={{
                            height: "12px",
                            marginTop: "-5px",
                            padding: "0",
                          }}
                          src="/images/Icons/rounded-cross.svg"
                          alt="✓"
                        />
                      )}
                  </span>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      {question?.question__video_explanation ? (
        <div className="explanation-container">
          <div className="">
            {/* <h3 className="title">Explanation</h3>
            <p className="explanation-text">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {question.question__answer_explanation}
              </ReactMarkdown>
            </p> */}

            <div className="d-flex justify-content-center align-items-center my-2">
              <a
                style={{ textDecoration: "underline", cursor: "pointer" }}
                className="explanation-video-opener-btn"
                onClick={() =>
                  handleExplanationModalShow(
                    question?.question__video_explanation || ""
                  )
                }
              >
                VIDEO EXPLANATION
              </a>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ResultQuestion;
